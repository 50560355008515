import React, { useState } from 'react'
import Navbar from '../landing/header/Navbar'
import './mintdomain.scss'
import Modal from 'react-bootstrap/Modal';

function MintDomain() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Navbar />
            <section className="mintdomain-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-11 col-lg-11 m-auto p-0">
                            <div className="mintD-head">
                                <img src="\MintNo\backtodoman.svg" alt="mintnow img" />
                                <h2>Mint Domains</h2>
                                <p>Once minted your wallet address will be set as the domain owner</p>
                            </div>
                            <div className="walletMint">
                                <div className="chooseWallet">
                                    <span><img src="\MintNo\1.svg" alt="" /> Choose Wallet</span>
                                    <span></span>
                                    <span><img src="\MintNo\2.svg" alt="" /> Mint Domains</span>
                                </div>
                                <div className="metaWallet">
                                    <div className="row">
                                        <div className="col-xl-6 mx-auto p-0">
                                            <div className="row">
                                                <div className="col-md-6  ">
                                                    <div className="metamask">
                                                        <img src="\MintNo\metamask.svg" className='img-fluid' alt="" />
                                                        <h6>Metamask</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-md-0 mt-5">
                                                    <div className="metamask">
                                                        <img src="\MintNo\walletConnect.svg" className='img-fluid walletkk' alt="" />
                                                        <h6>WalletConnect</h6>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearWallet">
                                    <img src="\MintNo\worldSkull.png" alt="" />
                                    <p>Clear WalletConnect Data</p>
                                </div>
                                {/* //confirm minting */}
                                <div className="mintConfirm">
                                    <div className="row">
                                        <div className="col-xl-6 text-center mx-auto p-0">
                                            <h3>Domain To Be Minted</h3>
                                            <p>domain.tomi</p>
                                            <div className="mintConfirmTopB">
                                                <p>Domain owner will be assigned to the following wallet address:</p>
                                                <p className='text-truncate'>0x0F4Dc5c90b64437B9da458806cFb958404D6B5D8</p>
                                                <div className='d-flex justify-content-center align-items-center chekcokd'>
                                                    {/* <input type="checkbox" name="" id="" /> */}
                                                    <label class="containerr">
                                                        <input type="checkbox" className='d-none' />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <p>I understand</p>
                                                </div>
                                            </div>

                                            <button className="img-btn w-100">
                                                Confirm
                                            </button>
                                            <div className="warningDiv">
                                                <img src="\MintNo\warning.png" alt="" />
                                                <p>This action is irreversible</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* //confirm minting */}
                                {/* // minting process */}
                                <div className="mintConfirm">
                                    <div className="row">
                                        <div className="col-xl-6 text-center mx-auto p-0">
                                            <img src="\MintNo\timer.svg" className='img-fluid mb-4 rotate ' alt="" />
                                            <h3>Minting Process Started</h3>
                                            <p className='mP-txt'>Your domains are now being deployed into the blockchain and your crypto wallet</p>
                                            <div className="mintConfirmTopB">
                                                <p className='tct'>This transaction is being executed on the polygon network. When the transaction is complete. Your domains will show on the blockchain</p>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-8 mx-auto p-0">
                                                    <button className="img-btn w-100">
                                                        Track Progress
                                                    </button>
                                                    <button className='img-btn-outline w-100 d-block mt-4' >
                                                        Back to domains
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* // minting process*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <button onClick={handleShow}>oops modal btn</button> */}
            <Modal className='oops-modal' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Oops…</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="inner-content">
                        <div className="uper-img">
                            <img src="\assets\newlanding-assets\oops-mint.svg" alt="img" className='img-fluid' />
                        </div>
                        <div className="bottom-content">
                            <p className='main-para'>It looks like you don’t have TOMI tokens in your wallet. You will need TOMI tokens to mint tDNS domains.</p>
                            <div className="inner-exchanges">
                                <h6>Buy on major exchanges</h6>
                                <div className="exchange-names">
                                    <a href="https://www.huobi.com/en-us/trade/tomi_usdt" target='_blank'><img src="\assets\newlanding-assets\huobi.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://www.gate.io/trade/TOMI_USDT" target='_blank'><img src="\assets\newlanding-assets\gateio.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://www.mexc.com/exchange/TOMI_USDT" target='_blank'><img src="\assets\newlanding-assets\mexc.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://crypto.com/exchange/trade/TOMI_USD" target='_blank'><img src="\assets\newlanding-assets\bitmart.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://www.bybit.com/en-US/trade/spot/TOMI/USDT" target='_blank'><img src="\assets\newlanding-assets\bybit.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://www.bitget.com/spot/TOMIUSDT?type=spot" target='_blank'><img src="\assets\newlanding-assets\bitget.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://phemex.com/spot/trade/TOMIUSDT" target='_blank'><img src="\assets\newlanding-assets\heli.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://www.kucoin.com/trade/TOMI-USDT" target='_blank'><img src="\assets\newlanding-assets\kucoin.svg" alt="img" className='img-fluid' /></a>
                                    {/* <a href="#" target='_blank'><img src="\assets\newlanding-assets\xcoin.svg" alt="img" className='img-fluid' /></a> */}
                                </div>
                               <img src="\assets\newlanding-assets\or-img.svg" alt="img" className='img-fluid para-or' />
                                <div className="twice-btns">
                                    <button className='uni-swap'> <img src="\assets\newlanding-assets\uniswap.svg" alt="img" className='img-fluid mr-2' /> Buy on Uniswap</button>
                                    <button className='btn-chan'> <img src="\assets\newlanding-assets\changely.svg" alt="img" className='img-fluid mr-2' /> Buy on Changelly</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MintDomain