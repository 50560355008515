import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketPlaceContract } from "../../utils/contractHelpers";

export const CheckHigh = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.MarketContract;
  const contract = getMarketPlaceContract(tokenAddress, web3);
  const HighestBid = useCallback(
    async (tokenId) => {
      try {
        // tokenIdForCurrentBid
        const data = await contract.methods.getBiddings(tokenId).call();
        // console.log('first succ',data)
      return data;
      } catch (error) {
      }
    },
    [contract]
  );

  return { HighestBid: HighestBid };
};

export default CheckHigh;
