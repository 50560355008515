import React from 'react'
import Navbar from '../landing/header/Navbar'
import "./signup.scss"
const Login = () => {
  return (
    <>
    <Navbar />
       <section className='login'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-xl-12 p-0'>
                    <div className='row'>
                        <div className='col-xl-3 col-md-5 col-12 m-auto p-0'>
                            <div className='signupbox'>
                                <div className='logo-head'>
                                   <img src='\assets\Logo\Group5.svg' className='img-fluid'/>
                                </div>
                                <div className='loginbox'>
                                    <p>Unstoppable Domains is the #1 provider of NFT domains. These aren‘t just traditional domains, these are domains with superpowers. They make crypto transactions simple, are your login to Web3 and your name on the internet.</p>
                                </div>
                                <div className='logohead'>
                                    <h2>Login to <span>TDNs</span></h2>
                                    <input type="text" placeholder='Enter your domain name' />
                                </div>
                                <div className='terms'>
                                    <p>By continuing, you are agreeing to<br></br> Unstoppable Domain's <span> terms and conditions </span> and <span>  privacy policy</span>.</p>
                                </div>
                                <a className='continue'>Continue</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Login