import React from 'react'
import Navbar from '../landing/header/Navbar'
import './checkout.scss'
import CreditCard from './creditCard/CreditCard'
import CryptoApp from './cryptoApp/CryptoApp'
import Cryptocurrency from './Cryptocurrency/Cryptocurrency'
import Paypal from './Paypal/Paypal'
function Chekout() {
    return (
        <>
        <Navbar />
        <section className="chekoutt-main">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-xl-11 col-lg-11 m-auto ">
                        <h1 className='checkMain'>Checkout</h1>
                        <div className="payment-tabs">
                            <div class="table-responsive">
                                <ul class="table nav nav-pills mb-3 flex-nowrap" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link  active navlik-1" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true"><img src="\chekout\card.svg" alt="" /> Credit Card</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false"><img src="\chekout\paypal.svg" alt="" /> PayPal</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false"><img src="\chekout\crypto.svg" alt="" /> Cryptocurrency</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link navlik-4" id="pills-contact-tab-4" data-toggle="pill" href="#pills-contact-4" role="tab" aria-controls="pills-contact-4" aria-selected="false"><img src="\chekout\cryptoApp.svg" alt="" /> Crypto.com App</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"><CreditCard /></div>
                                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"><Paypal/></div>
                                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"><Cryptocurrency/></div>
                                <div class="tab-pane fade" id="pills-contact-4" role="tabpanel" aria-labelledby="pills-contact-tab-4"><CryptoApp/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Chekout