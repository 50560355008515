import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketPlaceContract } from "../../utils/contractHelpers";

export const CheckExpire = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.MarketContract;
  const contract = getMarketPlaceContract(tokenAddress, web3);
  const CheckExpiry = useCallback(async (tokenId) => {
    try {
       const data = await contract.methods.getAuctions(tokenId).call();
    // // console.log('orios',new Date(parseInt(data.expiryTime) * 1000))
    return data;
    } catch (error) {
       console.log('orios',error)
    }
  }, [contract]);

  return { CheckExpiry: CheckExpiry };
};

export default CheckExpire;