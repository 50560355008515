import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import "./signup.scss"

const Success = () => {
    return (
        <>
        <Navbar />
            <section className='success'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xl-12 p-0'>
                            <div className='row'>
                                <div className='col-xl-3 col-md-5 col-12 m-auto p-0'>
                                    <div className='signupbox'>
                                        <div className='main-head'>
                                            <h3>Success!</h3>
                                        </div>
                                        <div className='main-para'>
                                            <p className='para'>  An email has been sent to ub1894497@gmail.com with a link to reset your account.</p>
                                            <p className='para'>  If you have not received the email after a few minutes, please check your spam folder.</p>
                                            <p className='para'>  Need help? Contact <br></br><span>support@tdns.com</span></p>
                                        </div>
                                        <div className='passwordreset'>
                                           <Link to="/login"> <button>Back</button></Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Success