import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { gettomiTokenContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

export const Allowance = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const contractAddress = environment.tomiToken;
    const contract = gettomiTokenContract(contractAddress, web3);
    const checkAllowance = useCallback(async (account,contractAdd) => {
        try {
            const details = await contract.methods
                .allowance(account, contractAdd)
                .call({
                    from: account,
                })
            // // console.log('error of the allowance---contract', details)
            return details

        } catch (error) {
            // // console.log('error of the allowance---contract-error', error)
            throw error;
           
        }
    }, [contract, account]);

    return { checkAllowance: checkAllowance };
};

export default Allowance;
