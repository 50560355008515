import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Commision from '../../hooks/dataFetchers/commision'
import MinterClaimReward from '../../hooks/dataSenders/minterClaimReward'
import { useWeb3React } from '@web3-react/core';
function TableRow({ item,partnerId, setLoader, setApiCallStatus, apiStatus }) {
    const [format, setFormat] = useState()
    const [commision, setCommision] = useState(0)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { currCommision } = Commision()
    const { ClaimCommison } = MinterClaimReward()
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const { account } = useWeb3React()
    // console.log('first',mintedData)
    const formatDate = () => {
        // Epoch time in seconds
        var epochTime = item?.startTime;

        // Create a new Date object with the Epoch time
        var date = new Date(epochTime * 1000);

        // Get the individual components of the date
        var year = date.getFullYear();
        var month = date.getMonth() + 1; // Months are zero-based, so add 1
        var day = date.getDate();

        // Format the components as a string in the desired format
        var formattedDate = `${day}/${month < 10 ? '0' : ''}${month}/${year}`;
        setFormat(formattedDate)
    }
    const getCommisionValue = async () => {
        try {
            const commision = await currCommision(partnerId)
            // console.log('asdflasjfl',item?.label , commision,partnerId)
            setCommision(commision)
            // console.log('first', commision)
        } catch (error) {
            // console.log('first', error)
            setCommision(0)
        }

    }
    const claimCommisionValue = async () => {

        try {
            setLoader(true)
            const claimed = await ClaimCommison(partnerId, account)
            handleShow()
            setApiCallStatus(!apiStatus)
            setLoader(false)
        } catch (error) {
            handleShow1()
            // console.log('first', error)
            setLoader(false)
        }

    }
    useEffect(() => {
        if (item) {
            formatDate()
            getCommisionValue()
        }

    }, [item])

    return (
        <>
            <tr>
                <td>{format}</td>
                <td>{item?.label?.substring(0, 25) || '928393uxij'}{JSON.stringify(item?.label)?.length > 25 && '(...)'}.{item?.tld}</td>
                <td>{commision?.length > 12 ? parseFloat(commision)?.toFixed(12) : commision} TOMI</td>
                <td style={{textAlign: "right"}}>
                    <button disabled={parseFloat(commision) === 0} className={parseFloat(commision) > 0 ? 'btn-claim' : 'btn-claim btnDis px-3'} onClick={claimCommisionValue}>{parseFloat(commision) === 0 ? 'Claimed' : 'Claim'}</button>
                </td>
            </tr>
            <Modal className='success-modal' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>claim commissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-content">
                        <img src="\assets\success2.svg" alt="img" className='img-fluid' />
                        <h6> Claimed Successfully</h6>
                    </div>
                    <button onClick={handleClose} className='btn-claim'>Okay</button>
                </Modal.Body>
            </Modal>

            <Modal className='success-modal' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>claim commissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-content">
                        <img src="\assets\error-icon.svg" alt="img" className='img-fluid' />
                        <h6>Failed to claim commission. try again</h6>
                    </div>
                    <button onClick={handleClose1} className='btn-claim'>Okay</button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TableRow