import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketPlaceContract } from "../../utils/contractHelpers";
const Claim = () => {
    const web3 = useWeb3();
    const contractAddress = environment.MarketContract;
    const contract = getMarketPlaceContract(contractAddress, web3);
    const UserClaim = useCallback(
        async (tokenId, account) => {
            try {
                var gasFunPrice;
                web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 3000000000
                    // // console.log("gasfun", typeof result2, result2)
                    gasFunPrice = result2.toString()
                })
                const gas = await contract.methods
                    .claim(tokenId).estimateGas({ from: account });
                const details = await contract.methods
                    .claim(tokenId)
                    .send({
                        from: account,
                        gas,
                        gasPrice: gasFunPrice
                    })
                return details;
            } catch (error) {
                throw (error)
            }
        },
        [contract]
    );
    return { UserClaim: UserClaim };
};
export default Claim;