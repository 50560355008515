import React from 'react'
import Navbar from '../landing/header/Navbar'
import "./billing.scss"
const Billing = () => {
  return (
    <>
    <Navbar />
    <section className='main-billing'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='main-heading'>
                        <h2>No orders yet</h2>
                        <p><span>Search </span>for a new domain or <span>transfer</span> a domain you already own</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Billing