import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getEthRegistrarContract } from "../../utils/contractHelpers";
import { getRegistryAddressContract } from "../../utils/contractHelpers";
import { getwrapperAddressContract } from "../../utils/contractHelpers";
import { getSetContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import { soliditySha3 } from 'web3-utils';
import namehash from 'eth-ens-namehash'
const DomainProfileCont = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const registrycontractAddress = environment.registryAddress;
    const resolverAddress = environment.resolver
    const contractRegistry = getRegistryAddressContract(registrycontractAddress, web3);
    const contractAddress = environment.ethRegistrar;
    const contract = getEthRegistrarContract(contractAddress, web3);

    // // console.log('account of the day', account)
    const domainProfileFun = useCallback(
        async (name, label, address, tokenId) => {
            const node = namehash.hash(name)
            const labelhash = soliditySha3(label)
            // // console.log('subdomain prop', node, labelhash)
            try {
                var gasFunPrice;
                web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 3000000000
                    // // console.log("gasfun", typeof result2, result2)
                    gasFunPrice = result2.toString()
                })
                // const res0 = await contractRegistry.methods.getData(tokenId).call()
                // // // console.log('res0',res0,res0?.expiry)
                const gas = await contractRegistry.methods.setSubnodeRecord(node, labelhash, address, resolverAddress, 600).estimateGas({ from: account, })
                const res = await contractRegistry.methods.setSubnodeRecord(node, labelhash, address, resolverAddress, 600).send({ from: account, gas: gas, gasPrice: gasFunPrice })
                // // console.log('subdomain success', res)
                return res
            } catch (error) {
                // // console.log('subdomain fail', error)
                throw error
            }

        },
        [contract, account]
    );
    return { domainProfileFun: domainProfileFun };
};
export default DomainProfileCont;