import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketPlaceContract } from "../../utils/contractHelpers";
const AffiliateClaimReward = () => {
    const web3 = useWeb3();
    const contractAddress = environment.market;
    const contract = getMarketPlaceContract(contractAddress, web3);
    const ClaimAffiliate = useCallback(
        async (tokenId, amount, account, v, r, s, id) => {
            try {
                // console.log('sdjfoasfjdasdo', tokenId,amount)
                var gasFunPrice;
                web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 3000000000
                    // // console.log("gasfun", typeof result2, result2)
                    gasFunPrice = result2.toString()
                })
                const gas = await contract.methods
                    .affiliateClaim(tokenId, amount?.toString(), id, v, r, s).estimateGas({ from: account });
                const details = await contract.methods
                    .affiliateClaim(tokenId, amount?.toString(), id, v, r, s)
                    .send({
                        from: account,
                        gas,
                        gasPrice: gasFunPrice
                        // gas: 50000, 
                        // gasPrice: web3.utils.toWei('1', 'gwei'),
                    })
                return details;
            } catch (error) {
                throw (error)
            }
        },
        [contract]
    );
    return { ClaimAffiliate: ClaimAffiliate };
};
export default AffiliateClaimReward;