import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DomainProfile from '../../hooks/dataSenders/subdomain'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import ChildOfId from '../../hooks/dataFetchers/myDomains'
import { Link, useHistory } from 'react-router-dom'
import Environment from '../../utils/Environment'
function SubDomain({ UrlData, setLoader, tokenId, setFirst, first }) {
    const [inpoData, setInputData] = useState('')
    const { account } = useWeb3React()
    const { domainProfileFun } = DomainProfile()
    const [subDomains, setSubDomains] = useState()
    const { Mainfunct } = ChildOfId();
    const history = useHistory()
    // console.log('token id ', tokenId)
    const getNftsdata = async () => {
        if (account) {
            setLoader(true)
            var data = JSON.stringify({
                query: `
                {
                    domain(id: "${tokenId}") {
                      subdomains {
                        name
                        id
                      }
                    }
                  }
                `,
                variables: {},
            });

            var config = {
                method: "post",
                url: Environment.dnsGraph,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    setLoader(true);
                    console.log(response,'response');
                    // dispatch(userNfts(response.data.data.domains));
                    // setNft(response.data.data.domains);
                    // checkCondition(response.data.data.domains);
                    // getStakedNfts(response.data.data.domains);
                    //   setMorData(response?.data?.data?.account?.registrations)
                    //   myDomainsFun(response?.data?.data?.account?.registrations)
                    //   setActualDomainArr(response?.data?.data?.account?.registrations)
                    setSubDomains(response.data.data.domain.subdomains)
                    // // console.log('result of the sub Domains---->', response?.data?.data?.account?.registrations)
                    setLoader(false);
                })
                .catch(function (error) {
                    setLoader(false);
                    // setNft([]);
                    // // // console.log('result of the myDomains',error);
                });
        }
    };
    const confirCryptoChanges = async () => {
        // console.log('subdomain account', UrlData, inpoData, account, tokenId)
        if (inpoData.endsWith("-")) {
            toast.info("Domain Should Not End With Special Character", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        } else if (inpoData.length < 3) {
            toast.info("Character Length should be greater then 2", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }
       else if (inpoData && account) {
            try {
                setLoader(true)
                const res = await domainProfileFun(UrlData, inpoData, account, tokenId)
                toast.success('SubDomain set Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                setInputData('')
                // // console.log('domainProfile contract data succ', res)
                toast.info('Subdomain will take time to show', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                setLoader(false)
                getNftsdata()
                setTimeout(() => {
                    getNftsdata()
                }, 7000);
            } catch (error) {
                // // console.log('subdomain error', error)
                toast.error(error?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0]?.toUpperCase() || error?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                setLoader(false)
            }
        } else {
            toast.error('Account or input data is missing!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }
    }

    const close=()=>{
        setInputData('')
    }
    const myDomainsFun = async (rawArray) => {
        let dumArray = []
        if (subDomains?.length > 0) {

            // for (let i = 0; i < ?; i++) {
            try {
                const res = await Mainfunct("0x3b8f5f02f77cf61f8719abbdd42ea10aabd152d0054ab020c1cac0d439463b00")
                dumArray.push(res)
            } catch (error) {
                // // console.log('subjdhfbcndomain', error)
            }

            // }
        }
        // // console.log('subjdhfbcndomain', dumArray)
        // rawArray.forEach(async (item, index, arr) => {
        //   // // console.log('result of the myDomains ==============>no---', index)
        //   arr[index] = await Mainfunct(item?.domain?.token_id)
        // })
        // setActualDomainArr(rawArray)
    }
    const manageSub = (item) => {
        setFirst(!first)
        history.push(`/domainProfile/${JSON.stringify(item)}`)
        history.go(0)
    }
    
    const inputVAlue = (e) => {
        const value = e.target.value.replace(/\s/g, "");
        const pattern = /^[A-Za-z0-9-]*$/; // Regular expression pattern

        if (pattern.test(value)) {
            const inpoval = value.toLowerCase().split('.');

            setInputData(value.toLowerCase());
            // if (inpoval[1] === 'tomi') {
            //     setDomainType(0);
            // } else if (inpoval[1] === 'com') {
            //     setDomainType(1);
            // } else {
            //     setDomainType(0);
            // }
            // //   else if (inpoval[1] !== '' && inpoval[1] !== 'tomi' && inpoval[1] !== 'com') {
            // //     setDomainType(-1);
            // //   }
            // setInputVal(inpoval[0]);
        }
    };
    
    useEffect(() => {
       ( UrlData && account ) && getNftsdata()
        myDomainsFun()
    }, [account, UrlData])
    const UrlEncode2 = (item) => {
        setInputData('')
        const data = item;
        history.push({
          pathname: '/domainprofile',
          state: { data },
        });

      }
    return (
        <div className="website subDomain">
            <div className="top">
                <h5>Sub Domain</h5>
            </div>
            <div className="option-field subInner d-flex align-items-center">
                <input  onChange={inputVAlue} value={inpoData}  name='IpfsHash' type="text" placeholder="Type in a label for subdomain" />
                <div className="bottom-btn mt-0 pt-0">
                    <button onClick={confirCryptoChanges} className='ml-2 pl-4 pr-4'>Save</button>
                    <button className='ml-2' onClick={close}>Cancel</button>
                </div>
            </div>
            {/* {// console.log('result of the sub Domains----> map', subDomains,UrlData)} */}
            {subDomains?.map((item) => {
                // return (
                        return (
                            <div className="domainDelete align-items-center">
                                <div className="imgName d-flex align-items-center">
                                    <img className='img-fluid' src="\searchResults\polyInfinite.svg" alt="" style={{ width: '30px' }} />
                                    <h6 className=''>{item?.name?.slice(0, 33)}</h6>
                                </div>
                                {/* <Link  to={}> */}
                                <button onClick={() => UrlEncode2(item)} className=" img-btn-outline">
                                    <img src="\searchResults\setting.svg" alt="" />
                                    Manage
                                </button>
                                {/* </Link> */}
                                {/* <img src="\assets\trash.svg" alt="" /> */}
                            </div>
                        )
                    // }))
            })}

            {/* <div className="domainDelete">
                <div className="imgName d-flex">
                    <img className='img-fluid' src="\assets\box.svg" alt="" />
                    <h6>rana.salman123.eth</h6>
                </div>

                <img src="\assets\trash.svg" alt="" />
            </div> */}
        </div>
    )
}

export default SubDomain