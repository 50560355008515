import React, { useEffect } from 'react';
import Footer from '../landing/footer/Footer';
import Navbar from '../landing/header/Navbar';
import "./account.scss";

const AccountSetting1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Navbar />
    <section className="accountsetting1">
        <div className="row">
            <div className="container">
               <div className="top-heading">
                <h1>Account Settings</h1>
               </div>
               <div className="bottom-card">
                <h4>Email Address</h4>
                <div className="option-field">
                    <label>Email Address</label>
                    <div className="text">
                        <p>john_doe@gmail.com</p>
                        <h6>Confirm</h6>
                    </div>
                </div>
               </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default AccountSetting1