import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getEthRegistrarBulkContract } from "../../utils/contractHelpers";
import { getNewResolverContract } from "../../utils/contractHelpers";
import { getApproveContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import { soliditySha3 } from 'web3-utils';
import Environment from "../../utils/Environment";
import namehash from 'eth-ens-namehash'
const DomainMinting = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const tokenAddress = environment.ethRegistrarBulk;
    const contract = getEthRegistrarBulkContract(tokenAddress, web3);
    const tokenAddressResolver = environment.resolverNew;
    const contractResolver = getNewResolverContract(tokenAddressResolver, web3);
    const DAYS = 24 * 60 * 60
    const REGISTRATION_TIME = 28 * DAYS
    const secret = '0x0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF'
    const domainMintingMethod = useCallback(
        async (dumArr) => {
            let filteredCartArray = dumArr;
            var gasFunPrice;
            web3.eth.getGasPrice().then((result) => {
                var result2 = parseInt(result) + 3000000000
                // // console.log("gasfun", typeof result2, result2)
                gasFunPrice = result2.toString()
            })
            try {
                console.log("xcart4", filteredCartArray)
                const resResolvers = await Promise.all(
                    filteredCartArray.map(async (item) => {
                        const { name, tld } = item;
                        const nameHash = namehash.hash(name + `.${tld}`);
                        const resResolver = await contractResolver.methods.setName(nameHash, name + `.${tld}`).encodeABI();
                        return [resResolver];
                    })
                );
                console.log('xcart5', resResolvers)
                if (resResolvers) {

                    console.log('register', filteredCartArray, REGISTRATION_TIME, Environment.resolver, resResolvers, false)
                    const gas = await contract.methods.register(filteredCartArray, REGISTRATION_TIME, Environment.resolverNew, resResolvers, false).estimateGas({
                        value: 0,
                        from: account
                    })
                    const res3 = await contract.methods.register(filteredCartArray, REGISTRATION_TIME, Environment.resolverNew, resResolvers, false).send({
                        value: 0,
                        from: account,
                        gas: gas,
                        gasPrice: gasFunPrice
                    })
                    return res3;

                    // // console.log('res of the delay', res)
                }


            } catch (error) {
                console.log('res of the delay', error)
                throw error
            }
        },
        [contract, account]
    );
    return { domainMintingMethod: domainMintingMethod };
};
export default DomainMinting;