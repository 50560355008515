import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketPlaceContract } from "../../utils/contractHelpers";
const AffiliateClaimRewardMany = () => {
    const web3 = useWeb3();
    const contractAddress = environment.market;
    const contract = getMarketPlaceContract(contractAddress, web3);
    const ClaimAffiliateMany = useCallback(
        async (tokenId, amount, account, v, r, s, id) => {
            console.log("tokenId, amount?.toString(), v, r, s", tokenId, amount, id, v, r, s)
            try {
                // console.log('sdjfoasfjdasdo', tokenId,amount)
                var gasFunPrice;
                web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 3000000000
                    // // console.log("gasfun", typeof result2, result2)
                    gasFunPrice = result2.toString()
                })
                console.log("$$$$$$$$$$", tokenId, amount, id, v, r, s)
                const gas = await contract.methods
                    .affiliateClaimMultiple(tokenId, amount, id, v, r, s).estimateGas({ from: account });
                const details = await contract.methods
                    .affiliateClaimMultiple(tokenId, amount, id, v, r, s)
                    .send({
                        from: account,
                        gas,
                        gasPrice: gasFunPrice
                    })
                return details;
            } catch (error) {
                throw (error)
            }
        },
        [contract]
    );
    return { ClaimAffiliateMany: ClaimAffiliateMany };
};
export default AffiliateClaimRewardMany;