import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'

export const Signup2 = () => {
  return (
    <>
    <Navbar />
      <section className='main-signup2'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-xl-12 p-0'>
                    <div className='row'>
                        <div className='col-xl-3 col-md-5 col-12 m-auto p-0'>
                            <div className='signupbox'>
                                <div className='main-head'>
                                    <h3>Sign up</h3>
                                </div>
                                <div className='buttonss'>
                                <Link to="/login"><button className='btn-pink'><img src='\assets\Logo\Group55.svg'className='img-fluid mr-2'/>Login with Unstoppable</button></Link>
                                    <button className='btn-pink'><img src='\assets\Logo\Mail.svg'className='img-fluid mr-2'/>Continue with Email</button>
                                    <button className='btn-white'><img src='\assets\Logo\Google - Original.svg'className='img-fluid mr-2'/>Continue with Google</button>
                                    <button className='btn-white'><img src='\assets\Logo\wallet-2.svg'className='img-fluid mr-2'/>Connect Wallet</button>
                                    <button className='btn-white'><img src='\assets\Logo\Twitter - Original.svg'className='img-fluid mr-2'/>Continue with Twitter</button>
                                    <a className='alreadyhavean-btn text-center mb-4'>Don't have an account?</a>
                                    <Link to="/signup"><button className='btn-white'>Sign Up</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
