import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getEthRegistrarContract } from "../../utils/contractHelpers";
import { getRegistryAddressContract } from "../../utils/contractHelpers";
import { getPublicResolverContract } from "../../utils/contractHelpers";
import { getSetContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import { soliditySha3 } from 'web3-utils';
import namehash from 'eth-ens-namehash'
const DomainProfileCont = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const registrycontractAddress = environment.registryAddress;
    const CAddr = environment.resolver
    const publicResolverContractStatic = getPublicResolverContract(CAddr, web3)
    const contractRegistry = getRegistryAddressContract(registrycontractAddress, web3);
    const contractAddress = environment.ethRegistrar;
    const contract = getEthRegistrarContract(contractAddress, web3);
    // // console.log('account of the day', account)
    const domainProfileFun = useCallback(
        async (node, key, value, bool) => {
            // // console.log('good ', key, value)
            const name = namehash.hash(node)
            // // console.log('good hashiiii', node)
            if (bool) {


                if (name) {
                    const resContract = await contractRegistry.methods.resolver(name).call();
                    // // console.log('good registry jo', resContract)
                    if (resContract) {
                        const publicResolverContract = await getPublicResolverContract(resContract, web3);
                        // // console.log("hrtr is contrsct", publicResolverContract)
                        try {
                            const res = await publicResolverContract.methods.text(name, key).call()
                            // // console.log('good publicResolver settext', res)
                            return res;
                        } catch (error) {
                            throw error
                        }
                    }
                }
            } else {
                if (name) {
                    var gasFunPrice;
                    web3.eth.getGasPrice().then((result) => {
                        var result2 = parseInt(result) + 3000000000
                        // // console.log("gasfun", typeof result2, result2)
                        gasFunPrice = result2.toString()
                    })
                    const resContract = await contractRegistry.methods.resolver(name).call();
                    // // console.log('good registry', resContract, name)
                    if (resContract) {
                        const publicResolverContract = await getPublicResolverContract(resContract, web3);
                        // // console.log("hrtr is contrsct", publicResolverContract)
                        try {
                            const gas = await publicResolverContract.methods.setText(name, key, value).estimateGas({ from: account })

                            const res = await publicResolverContract.methods.setText(name, key, value).send({ from: account, gas: gas, gasPrice: gasFunPrice, })
                            // // console.log('good publicResolver settext', res)
                            return res;
                        } catch (error) {
                            // // console.log('good publicResolver settext', error)
                            throw error
                        }
                    }
                }
            }

            // if (bool) {
            //     try {
            //         // // console.log('domainProfile contract data contract', key, value, tokenId)
            //         const resContract = await contract.methods.resolverOf(tokenId).call();
            //         if (resContract) {
            //             // // console.log('resContract', resContract)
            //             const contract1 = await getSetContract(resContract, web3);
            //             const details = await contract1.methods
            //                 .get(key, tokenId)
            //                 .call()
            //             return details;
            //         }

            //     } catch (error) {
            //         throw (error)
            //     }
            // } else {
            //     try {
            //         // // console.log('domainProfile contract data contract', key, value, tokenId)
            //         const resContract = await contract.methods.resolverOf(tokenId).call();
            //         if (resContract) {
            //             // // console.log('resContract', resContract)
            //             const contract1 = await getSetContract(resContract, web3);
            //             const details = await contract1.methods
            //                 .set(key, value, tokenId)
            //                 .send({
            //                     from: account,
            //                 })
            //             return details;
            //         }

            //     } catch (error) {
            //         throw (error)
            //     }
            // }

        },
        [contract, account]
    );
    return { domainProfileFun: domainProfileFun };
};
export default DomainProfileCont;