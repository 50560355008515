import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { gettomiTokenContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
const Approve = () => {
    let { account } = useWeb3React()
    const web3 = useWeb3();
    const contractAddress = environment.tomiToken;
    const contract = gettomiTokenContract(contractAddress, web3);
    const UserApprove = useCallback(
        async (bulkAddress) => {
            var gasFunPrice;
            web3.eth.getGasPrice().then((result) => {
                var result2 = parseInt(result) + 3000000000
                // // console.log("gasfun", typeof result2, result2)
                gasFunPrice = result2.toString()
            })
            let amount = '9999999999999999999999999999';
            let tomiAmount = web3.utils.toWei(amount.toString(), "ether");
           let contractT
            if(bulkAddress){
                contractT= environment.ethRegistrarBulk
            }else{
                contractT= environment.ethRegistrar
            }
            try {
                const gas = await contract.methods
                    .approve(contractT, tomiAmount)
                    .estimateGas({
                        from: account,
                    })
                const details = await contract.methods
                    .approve(contractT, tomiAmount)
                    .send({gas: gas,gasPrice: gasFunPrice,from: account})
                return details;
            } catch (error) {
                throw (error)
            }
        },
        [contract]
    );
    return { UserApprove: UserApprove };
};
export default Approve;