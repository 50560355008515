import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'


const Resetpassword = () => {
    return (
        <>
        <Navbar/>
            <section className='resetpassword'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xl-12 p-0'>
                            <div className='row'>
                                <div className='col-xl-3 col-md-5 col-12 m-auto p-0'>
                                    <div className='signupbox'>
                                        <div className='main-head'>
                                            <h3>Reset password</h3>
                                        </div>
                                        <div className='emailinput'>
                                            <p>Email</p>
                                            <input type="text" placeholder='Email' />
                                        </div>
                                        <div className='passwordreset'>
                                           <Link to="/success"> <button className='send'>Send Password Reset</button></Link>
                                            <button>Back</button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Resetpassword