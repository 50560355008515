import web3NoAccount from "./web3";
import martketAbi from './TDNSMArket.json'
import searchDomainAbi from './searchDomainAbi.json'
import mintdomainAbii from './mintDomainAbi.json'
import cnsRegistry from './cnsRegistry.json'
import proxyReader from './proxyReader.json'
import getSetAbi from './getSetAbi.json'
import approveAbi from './approveAbi.json'
import EthRegistrar from './EthRegistrar.json'
import EthRegistrarBulk from './ethRegistrarBulk.json'
import registryAddress from './registryAddress.json'
import publicResolver from './publicResolver.json'
import newResolver from './newResolver.json'
import wrapperAddress from './wrapperAddress.json'
import rAddress from './registrarAddress.json'
import tToken from './tomiToken.json'
import uniSwap from './uniSwapRouterAbi.json'
const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount;
  // // // console.log('_web3',_web3);
  return new _web3.eth.Contract(abi, address);
};
export const getMarketPlaceContract = (address, web3) => {
  return getContract(martketAbi, address, web3);
};
export const getUNSRegistryContract = (address, web3) => {
  return getContract(searchDomainAbi, address, web3);
};
export const getMintingManagerContract = (address, web3) => {
  return getContract(mintdomainAbii, address, web3);
};
export const getCNSContract = (address, web3) => {
  return getContract(cnsRegistry, address, web3);
};
export const getProxyReaderContract = (address, web3) => {
  return getContract(proxyReader, address, web3);
};
export const getSetContract = (address, web3) => {
  return getContract(getSetAbi, address, web3);
};
export const getApproveContract = (address, web3) => {
  return getContract(approveAbi, address, web3);
};
export const getEthRegistrarContract = (address, web3) => {
  return getContract(EthRegistrar, address, web3);
};
export const getEthRegistrarBulkContract = (address, web3) => {
  return getContract(EthRegistrarBulk, address, web3);
};
export const getRegistryAddressContract = (address, web3) => {
  return getContract(registryAddress, address, web3);
};
export const getPublicResolverContract = (address, web3) => {
  return getContract(publicResolver, address, web3);
};
export const getNewResolverContract = (address, web3) => {
  return getContract(newResolver, address, web3);
};
export const getwrapperAddressContract = (address, web3) => {
  return getContract(wrapperAddress, address, web3);
};
export const getregistrarAddressContract = (address, web3) => {
  return getContract(rAddress, address, web3);
};
export const gettomiTokenContract = (address, web3) => {
  return getContract(tToken, address, web3);
};
export const getuniSwapRouter = (address, web3) => {
  return getContract(uniSwap, address, web3);
};

