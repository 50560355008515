import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import "./signup.scss"

const Signup1 = () => {
    return (
        <>
        <Navbar/>
            <section className='signup1'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xl-12 p-0'>
                            <div className='row'>
                                <div className='col-xl-3 col-md-5 col-12 m-auto p-0'>
                                    <div className='signupbox'>
                                        <div className='main-head'>
                                            <h3>Sign up</h3>
                                        </div>
                                        <div className='emailinput'>
                                            <p>Email</p>
                                            <input type="text" placeholder='Email' />
                                            <p>Password</p>
                                            <input type="text" placeholder='Password' />
                                            <Link to="/reset"><h6 className='resetpass'>Reset Password</h6></Link>
                                        </div>
                                        <div className='signupbutton'>
                                            <Link to="/signup2"><button>Sign Up</button></Link>
                                        </div>
                                        <div className='cancle'>
                                            <a href=''>Cancle</a>
                                        </div>
                                        <div className='brdr'></div>
                                        <div className='account p-0'>
                                            <h4>Already have an account?</h4>
                                        </div>
                                        <div className='login-btn'>
                                            <button>Log In</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Signup1