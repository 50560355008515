import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getEthRegistrarContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
// import { ethers  } from "ethers";
export const ChildOfId = () => {
  const { account } = useWeb3React()
  const web3 = useWeb3();

  const tokenAddress = environment.ethRegistrar;
  const contract = getEthRegistrarContract(tokenAddress, web3);
  const Mainfunct = useCallback(async (label,typeOD) => {
    // // console.log('ddddddddddddddddr',typeOD)
    try {
      const exists = await contract.methods.available(label,typeOD).call();
      // // console.log('res of the delay Availabe', exists)
      // // console.log('res:::::', exists)
      return exists;

    } catch (err) {
      // // console.log("ërrr", err)
      return err;
    }


  }, [account]);

  return { Mainfunct: Mainfunct };
};

export default ChildOfId;
