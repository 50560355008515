import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { gettomiTokenContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

export const ClaimToken = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const contractAddress = environment.tomiToken;
    const contract = gettomiTokenContract(contractAddress, web3);
    const claimTokenHook = useCallback(async (account) => {
        var gasFunPrice;
        web3.eth.getGasPrice().then((result) => {
            var result2 = parseInt(result) + 3000000000
            // // console.log("gasfun", typeof result2, result2)
            gasFunPrice = result2.toString()
        })
        try {
            const gas = await contract.methods
                .mint(account, '36000000000000000000000')
                .estimateGas({
                    from: account,
                })
            const details = await contract.methods
                .mint(account, '36000000000000000000000')
                .send({ gas: gas, gasPrice: gasFunPrice, from: account })
            return details

        } catch (error) {
            throw error;
        }
    }, [contract, account]);

    return { claimTokenHook: claimTokenHook };
};

export default ClaimToken;
